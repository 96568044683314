import React from 'react'
import {GiWeightLiftingUp} from "react-icons/gi"
import Dropdown from 'react-bootstrap/Dropdown';
import { IconContext } from "react-icons";
import { GoGear } from "react-icons/go";
import { Profile } from "../Profile"
import { Navbar } from "../ui/Navbar"
import { motion } from "framer-motion";

export function Stats() {
  
  return (
      
    <React.Fragment>

          <motion.div className="element-container"
          initial={{ scale: 0.97 }}
          animate={{ scale: 1 }}
          exit={{ x: "100%", opacity: 0 }}
          transition={{ duration: 0.1 }}>

        
          <div className="element">
          <div className='element-top'>
          <div className='element-title'>SESSIONS</div>
          </div>
          
            
          <div className='element-bottom'>
          <Profile />
          </div>
        </div> 
        
        <div className="element">
          <div className='element-top'>
          <div className='element-title'>PROGRESSION</div>
          </div>
          
            
          <div className='element-bottom'>
            CHART<br />
            CHART<br />
            CHART<br />
            CHART<br />
            CHART<br />
            
          </div>
          </div> 
        

      
        
        </motion.div> 

  

      <Navbar />
      
      </React.Fragment>
      
  )
}

function ExerciseItem() {
  
  return (
    <>
      <div className="exercise-item">
        
      Push HIIT
        
      
    </div>
    </>  
  )
}