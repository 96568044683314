import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'

export function HIITTimer(props) {

  const exercises = useSelector((state) => state.exercises)


  const [ConfigSet, setConfigSet] = useState(false)
  const [exerciseInterval, setExerciseInterval] = useState(30)
  const [breakInterval, setBreakInterval] = useState(30)
  const [currentExercise, setCurrentExercise] = useState(0);
  const currentExerciseName = exercises[currentExercise].exerciseName
  const NextExerciseName = exercises[currentExercise + 1] ? exercises[currentExercise + 1].exerciseName : "Finish"
  const [seconds, setSeconds] = useState();
  const [isActive, setIsActive] = useState(false);
  const [BreakActive, setBreakActive] = useState(false);
  
  
  const toggle = () => {
    setIsActive(!isActive)
    setSeconds(exerciseInterval)
    setConfigSet(!ConfigSet)
    setCurrentExercise(0)
    setBreakActive(false)
    
  }

  useEffect(() => {
    let interval = null;
    if (isActive) { interval = setInterval(() => { setSeconds(seconds => seconds - 1); }, 1000) }
    if (seconds === 0 && !exercises[currentExercise + 1]) {
      toggle();
    }
    if (seconds === 0 && !BreakActive) {
      setSeconds(breakInterval);
      setBreakActive(true)
    }
    if (seconds === 0 && BreakActive) {
      setSeconds(exerciseInterval);
      setCurrentExercise(currentExercise + 1);
      
      setBreakActive(false);
    }
      else if (!isActive && seconds !== 0) { clearInterval(interval); }
      return () => clearInterval(interval);
  }, [isActive, seconds, BreakActive, currentExercise, breakInterval, exerciseInterval, exercises]);
  

  if (!ConfigSet){
    return (
      <div className="timer hiit">
        <div className='timer-circle go' onClick={() => toggle()}>Start </div>
        <div className='hiitConfigWrapper'>
        <div className='hiitConfigItem'>
        Exercise time
        <input className="hiitConfigForm" value={exerciseInterval} type="number" autoComplete="off" onChange={event => setExerciseInterval(event.target.value)} />
        </div>
        <div className='hiitConfigItem'>
        Break time
        <input className="hiitConfigForm" value={breakInterval} type="number" autoComplete="off" onChange={event => setBreakInterval(event.target.value) }/> 
        </div>
        </div>
        </div>
      );
    }

 if (ConfigSet){
  return (
    <div className="timer hiit" onClick={() => toggle()}>

      <div className={`timer-circle ${BreakActive ? 'break' : ''}`}>{seconds}</div>

      <div className='timer-maintext'>
        {BreakActive ?
          <>
            <div className='timer-break'>Break</div>
            <div className='timer-next'>Next up: {NextExerciseName}</div>
            
          </>
          : currentExerciseName}
       </div>
      </div>
    );
  }
  
};
