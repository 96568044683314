import React from 'react'
import {useState} from 'react'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export function SignUp() {

    const auth = getAuth();
  
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
  
    const [error, setErrorState] = useState("");
  
    const position = 'top-center';
    const [showToast, setShowToast] = useState(false);
  
  

    const Register = () => {
      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          setEmail("");
          setPassword("");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          setErrorState({errorCode, errorMessage})
          setShowToast(true)
        });
    };
  
    
  return (
      
      <React.Fragment>
      
      <div className='formContainer'>
      <div className='formCard'>

       <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" value={email} placeholder="Enter email" onChange={(e) => setEmail(e.target.value)}/>
        
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
      </Form.Group>
      
      <Button variant="primary" onClick={Register}>
        Submit
      </Button>
        </Form>
        
        </div>
        </div>

      <ToastContainer className="p-3" position={position} >
      <Toast show={showToast} onClose={() => setShowToast(false)}>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">Sign-up failed</strong>
        <small>{error.errorCode}</small>
      </Toast.Header>
      <Toast.Body><span className="text-dark">{error.errorMessage}</span></Toast.Body>
      </Toast>
      </ToastContainer>

      
      </React.Fragment>
      
  )
}
