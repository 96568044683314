import React from 'react'
import { Link } from "react-router-dom";

export function SplashScreen() {

  return (
      
      <React.Fragment>
         <div className='splash'>
         <div className='splashLogo'>MYLIFTS</div>

          <div>
          <Link className='splashText' to="/login"><span className='splashLink'>SIGN IN</span></Link>
          <Link className='splashText' to="/signup"><span className='splashLink'>SIGN UP</span></Link>
          </div>
        
        </div>
      </React.Fragment>
      
  )
}
