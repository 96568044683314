import React, { useState, useEffect } from 'react';
import { IconContext } from "react-icons";
import { IoIosTimer, IoMdStopwatch } from "react-icons/io";
import { useLocation } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { GrUnorderedList } from "react-icons/gr";
import { Navbar } from "./Navbar"
import { HIITTimer } from "./HIITTimer"
import { Stopwatch } from "./Stopwatch"


export function Timer() {

  const [HIITEnabled, setHIITEnabled] = useState(false)

  return (
    <>
         <div className="element-container">
           <div className="element">
            <div className='element-top'>
            <div className='element-title'>{HIITEnabled ? "HIIT Timer" : "Stopwatch"}</div>        
              <div className='element-controls'>
                
              <Dropdown>
                <Dropdown.Toggle variant="ello">
                  <IconContext.Provider value={{ size: "1.9rem", className: 'SVGwhite' }}> <IoIosTimer /> </IconContext.Provider>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="exercise-hamburger">
                  <IconContext.Provider value={{ className: 'dropdownIcons',  size: "1.4rem", }}>
                    <Dropdown.Item onClick={() => setHIITEnabled(false)}><IoMdStopwatch />Stopwatch</Dropdown.Item>
                    <Dropdown.Item onClick={() => setHIITEnabled(true)} ><GrUnorderedList />HIIT Timer</Dropdown.Item>
                  </IconContext.Provider>
                </Dropdown.Menu>
              </Dropdown>
              </div>
            </div>
             {HIITEnabled ? <HIITTimer /> : <Stopwatch />}
      </div> 
   </div>
 <Navbar />
 </>
  )
};
