import React, { useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useList } from 'react-firebase-hooks/database';
import { ResponsiveCalendar } from 'nivo';
import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth } from "firebase/auth";
import dayjs from 'dayjs';


export function Profile() {

  const db = getDatabase();
  const auth = getAuth();

  const previous = ["1673035605883", "1673550926729", "1673807942848"]
  const checked = previous.map((item) => { return Number(dayjs(+item).format("DD")) })
  const currentMonth = "2023-1"
  const currentDay = dayjs().format("DD")
  const blanks = dayjs(currentMonth).startOf("month").day()
  const blanksArray = (Array.from(Array(blanks).keys()))
  const daysInMonth = dayjs(currentMonth).daysInMonth()
  const dayArray = (Array.from(Array(daysInMonth).keys()))

  return (
   
      <div className='calendar'>
        <div className='calendarLabel'>Sun</div>
        <div className='calendarLabel'>Mon</div>
        <div className='calendarLabel'>Tue</div>
        <div className='calendarLabel'>Wed</div>
        <div className='calendarLabel'>Thu</div>
        <div className='calendarLabel'>Fri</div>
        <div className='calendarLabel'>Sat</div>

        {blanksArray.map((item, iterator) =>
          <Day key={iterator}/>   
        )}

        {dayArray.map((item, iterator) =>
          <Day number={item + 1} key={iterator} active={checked} currentDay={currentDay} />   
        )}
        
      </div>
      
  )
}

function Day(props) {

  const dayNo = props.number

  const active = props.active ? props.active.includes(dayNo) : "";
  const isToday = props.currentDay === props.number
  

  
  return (
    
      <div className={`calendarDay ${active ? 'calendarDayActive' : ''}`}>
      {dayNo ? dayNo: ""}
      </div>
    
  )

}
