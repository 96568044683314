import React, { useState, useReducer } from 'react'
import { createBrowserRouter, RouterProvider, useLocation } from "react-router-dom";
import { SplashScreen } from './components/routes/splashScreen';
import { ErrorScreen } from './components/routes/errorScreen';
import { Overview } from './components/routes/Overview';
import { Home } from './components/routes/Home';
import { SignUp } from './components/routes/SignUp';
import { Loader } from './components/routes/Loader'; 
import { LogIn } from './components/routes/LogIn';
import { Stats } from './components/routes/Stats';
import { getAuth } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import app from "./firebase"
import { Timer } from './components/ui/Timer'
import { AnimatePresence } from "framer-motion";


export default function App() {

  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);

  const SkipIfAuth = ({ children }) => {
    if (loading) { return (<Loader />); }
    if (error) { return (<ErrorScreen />); }
    if (user) {return (<Navigate to="/overview" replace />);}
    return children;
  };

  const RequireAuth = ({ children }) => {
    if (loading) { return (<Loader />); }
    if (error) {return (<ErrorScreen />);}
    if (!user) { return (<Navigate to="/" replace />); }
    return children;
  };
  
  const router = createBrowserRouter([
    {
      path: "/",
      element: (<SkipIfAuth><SplashScreen /></SkipIfAuth>),
      errorElement: <ErrorScreen />,
      
    },
    
    {
      path: "/signup",
      element: <SkipIfAuth><SignUp /></SkipIfAuth>,
      errorElement: <ErrorScreen />,
    },

    {
      path: "/login",
      element: <SkipIfAuth><LogIn /></SkipIfAuth>,
      errorElement: <ErrorScreen />,
    },

    {
      path: "/overview",
      element: (<RequireAuth><Overview /></RequireAuth>),
      errorElement: <ErrorScreen />,
    },

    {
      path: "/home",
      element: (<RequireAuth><Home /></RequireAuth>),
      errorElement: <ErrorScreen />,
    },

    {
      path: "/timer",
      element: (<RequireAuth><Timer /></RequireAuth>),
      errorElement: <ErrorScreen />,
    },

    {
      path: "/stats",
      element: (<RequireAuth><Stats /></RequireAuth>),
      errorElement: <ErrorScreen />,
    },

    
  ]);


  return (
    <RouterProvider router={router} />
  );
}


