import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

export function Loader() {
    
  return (
      
    <React.Fragment>
      <div className='loader'>
      <Spinner animation="border" variant="secondary" />
       </div>
      </React.Fragment>
      
  )
}
