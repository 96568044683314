import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { set, ref } from "firebase/database";

export const FinishWorkout = createAsyncThunk(
  'FinishWorkout',
  async (params, {getState}) => {
    const auth = getAuth();
    const db = getDatabase();
    const state = getState();
    try {
      set(ref(db, `user/${auth.currentUser.uid}/sessions/${Date.now()}`), {
        workoutData: state.exercises,
      });
    } catch (e) {
      return console.error(e.message);
    }
  }
);

/* const sessions = ref(db, `user/${auth.currentUser.uid}/sessions/`);
onValue(sessions, (snapshot) => {
  const data = snapshot.val();
  const arraydata = Object.keys(data);
  
}); */

export const workoutSlice = createSlice({
  name: 'workout',

  initialState: {
    title: "New workout",
    exercises: [
      {exerciseName:"New exercise", reps:[4, 5, 6], weight:[27, 17, 57]},
      {exerciseName:"Tricep Dip", reps:[4, 5, 6], weight:[27, 17, 57]},
      {exerciseName: "Chest Press", reps: [8, 9, 10, 11], weight:[30, 10, 20, 20] },
      {exerciseName: "Ya Mum", reps: [8, 9, 10, 11], weight:[30, 10, 20, 20] },
      {exerciseName: "Chest Press", reps: [8, 9, 10, 11], weight:[40, 10, 20, 20] },
    ],
    HIITEnabled: false,
  },

  reducers: {

    removeRep: (state, action) => {
     const setID = action.payload[1]
     const currentExercise = action.payload[0]
     state.exercises[currentExercise].reps.splice(setID, 1)
     state.exercises[currentExercise].weight.splice(setID, 1);
    },
   
    addRep: (state, action) => {
      const currentExercise = action.payload
      state.exercises[currentExercise].reps.push(0);
      state.exercises[currentExercise].weight.push(0);
     },
   
    addLine: (state) => {
      state.exercises.push({exerciseName:"New exercise", reps:[], weight:[]});
    },

    deleteLine: (state, action) => {
      const line = action.payload
      state.exercises.splice(line, 1)
    },
   
    rename: (state, action) => {
      const currentExercise = action.payload[0]
      const newName = action.payload[1]
      state.exercises[currentExercise].exerciseName = newName
    },

    updateWeight: (state, action) => {
      const currentExercise = action.payload[0]
      const setID = action.payload[1]
      const newWeight = action.payload[2]
      state.exercises[currentExercise].weight[setID] = newWeight
    },

    updateReps: (state, action) => {
      const currentExercise = action.payload[0]
      const setID = action.payload[1]
      const newRep = action.payload[2]
      state.exercises[currentExercise].reps[setID] = newRep
    },

    clearRoutine: (state, action) => {
      state.exercises = []
    },

    renameRoutine: (state, action) => {
      const newTitle = action.payload
      state.title = newTitle
    },

    setExercises: (state, action) => {
      const newData = action.payload
      state.exercises = newData
    },
  },

  extraReducers: (builder) => {
   
    builder.addCase(FinishWorkout.fulfilled, (state, action) => {
      
      console.log(false)
    }
    )
    
    builder.addCase(FinishWorkout.pending, (state, action) => {
      
      console.log(true)
    }
    )
    
    builder.addCase(FinishWorkout.rejected, (state, action) => {
      
      console.log(false)
    }
    )


  },

})


export const { setExercises, clearRoutine, increment, decrement, removeRep, addRep, addLine, rename, deleteLine, updateWeight, updateReps, renameRoutine} = workoutSlice.actions

export default workoutSlice.reducer