import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { rename, deleteLine, addRep } from '../redux/workoutSlice'
import { removeRep, updateWeight, updateReps } from '../redux/workoutSlice'
import { GrAdd, GrClose, GrTrash} from "react-icons/gr";
import { motion } from "framer-motion";
import { ExerciseList } from "../data/ExerciseList"
import Dropdown from 'react-bootstrap/Dropdown';

import { IconContext } from "react-icons";

export function Summary({id, current, onDelete, activeExercise, setActiveExercise}) {
  
 
  const dispatch = useDispatch()

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchActive, setSearchActive] = useState(false)
  const SearchResult = ExerciseList.filter(exercise => exercise.name.toLowerCase().includes(searchTerm.toLowerCase()));
  
  if (activeExercise === id){
    return (
      <motion.div className="summaryLine" >
        <div className='edit'>
          <div className="summary-top">
            <div className='editbar'>
                
              <Dropdown show={showDropdown} className="dropdown-exerciseName">
                <Dropdown.Toggle variant="exerciseName" onClick={(e) => setShowDropdown(true)}>
                     
                  <input className="exerciseNameForm" type="text" v
                    value={searchActive ? searchTerm : current.exerciseName}
                    placeholder={current.exerciseName}
                    onChange={(e) => setSearchTerm(e.target.value)} onClick={() => [setSearchTerm(""), setSearchActive(true)]} />
                      
                </Dropdown.Toggle>
                <Dropdown.Menu variant="exercise">
                  {SearchResult[0] ?
                    SearchResult.map(function (item, iterator) {
                      return <Dropdown.Item key={iterator}
                        onClick={(e) => [dispatch(rename([id, e.target.innerText])),
                        setSearchTerm(e.target.innerText),
                        setShowDropdown(false),
                        setSearchActive(false)]}>
                        {item.name}</Dropdown.Item>;
                    })
                    : <Dropdown.Item key={9999}>No Results</Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>
              
              <div className='modalButtonBar'>
                <IconContext.Provider value={{ className: 'SVGsecondary' }}>
   
                  <button className="modalActionButton" onClick={() => dispatch(addRep(id))}>       <GrAdd /></button>
                  <button className="modalActionButton" onClick={() => [setActiveExercise("none"), dispatch(deleteLine(id))]}><GrTrash /></button>
                  <button className="modalActionButton" onClick={() => [setShowDropdown(false), setActiveExercise("none")]} ><GrClose /></button>
                </IconContext.Provider>
              </div>

            </div>
          </div>

          
        </div>

        {current.reps.length > 0 ? 
          
          current.reps.map((rep, index) =>
            <Counter
              key={index}
              currentExercise={id}
              setID={index}
              repCount={rep}
              weight={current.weight[index]}
          />)
        
          : 
          
          <div className="noExercises">
           No sets
          </div>
        
        
        }

       
        
      </motion.div>
    )
  }

  if (activeExercise === "none"){
    return (
      <motion.div className="summaryLine">
        <div className='edit'>
          <div className="summary-top">
            <div className='editbar'>
            <div className='exerciseNameTag' onClick={() => setActiveExercise(id)}>
            {current.exerciseName}
              </div>  
              
            </div>
          </div>
        </div>
      </motion.div>
    )
  }

}

/* function Rep(props) {
  const index = props.index
  const rep = props.rep
  const deduped = props.deduped
  const weight = props.weight
  const currentWeight = weight[index]
  const weightIndex = deduped.indexOf(currentWeight)
  return (
      <>
      <div className={`infobadge exercise color${weightIndex}`}>
        {rep}
      </div>
      </>
  )
}

function WeightBadge(props) {
  const weight = props.weight
  const index = props.index
  return (
      <>
      <div className={`summaryLabel weight color${index}`}>{weight}kg</div>
      </>
  )
}
 */
function Counter(props) {
  const dispatch = useDispatch()
  const currentExercise = props.currentExercise
  const repCount = props.repCount
  const weight = props.weight
  const setID = props.setID
  return (
    <div className='modalLine'>
      <div className='holder'>
      <div className='holder-lefthalf'>
      <input className="exerciseWeightForm" defaultValue={weight} type="number" autoComplete="off" onChange={event => dispatch(updateWeight([currentExercise, setID, event.target.value]))}/> 
      </div>
      <div className='holder-righthalf'>kg</div>
      </div>
      <div className='holder'>
      <div className='holder-lefthalf'>
      <input className="exerciseWeightForm" defaultValue={repCount} type="number" autoComplete="off" onChange={event => dispatch(updateReps([currentExercise, setID, event.target.value]))}/> 
      </div>
      <div className='holder-righthalf'>reps</div>
      </div>
      <IconContext.Provider value={{ className: 'SVGsecondary' }}> 
      <button className="modalbutton" onClick={() => dispatch(removeRep([currentExercise, setID]))}><GrTrash /></button>
      </IconContext.Provider> 
      </div>
  )
}