import React from 'react'
import { useState, useEffect } from 'react'
import { Summary } from '../summary'
import { useDispatch, useSelector } from 'react-redux'
import { GrMenu, GrAdd, GrTrash, GrBlockQuote, GrDocumentDownload, GrUnorderedList, GrApps, GrLinkPrevious, GrLinkNext} from "react-icons/gr";
import { FaRegSave } from "react-icons/fa";
import { AiOutlineCheck } from "react-icons/ai";
import { IoIosTimer, IoMdStopwatch } from "react-icons/io";
import { addLine } from '../../redux/workoutSlice'
import { IconContext } from "react-icons";
import Dropdown from 'react-bootstrap/Dropdown';
import { FinishWorkout, clearRoutine, renameRoutine } from '../../redux/workoutSlice'
import Form from 'react-bootstrap/Form';
import Stopwatch from '../ui/Stopwatch';
import HIITTimer from '../ui/HIITTimer';
import { Navbar } from "../ui/Navbar"
import { motion } from "framer-motion";
import { Reorder } from "framer-motion"
import { AnimateSharedLayout } from "framer-motion"


export function Overview() {

  const dispatch = useDispatch()
  const exercises = useSelector((state) => state.exercises)

  const title = useSelector((state) => state.title)
  const [editTitleToggle, setEditTitleToggle] = useState(false)
  const [currentExercise, setCurrentExercise] = useState(0)
  const [newTitle, setNewTitle] = useState("")
  const [activeExercise, setActiveExercise] = useState("none")
  const HandleFinishWorkout = () => {
    if (window.confirm("End workout and upload stats?") == true) {
      dispatch(FinishWorkout());
    } 
  }

  const ConfirmEditTitle = () => {
    setEditTitleToggle(false);
    dispatch(renameRoutine(newTitle));
    setNewTitle(title);
  }

  const CancelEditTitle = () => {
    setEditTitleToggle(false);
    setNewTitle(title);
  }



    return (
      <>
      <AnimateSharedLayout>
          <motion.div className="element-container" layout
            
            initial={{ scale: 0.97 }}
          animate={{ scale: 1 }}
          exit={{ x: "100%", opacity: 0 }}
          transition={{ duration: 0.2 }}>
 
         
        <motion.div className="element exerciseSummary" layout="position" >
          <div className='element-top'>

             {editTitleToggle ?
              <><Form.Control type="text"  placeholder={title} className="editTitleForm"
                onChange={(e) => setNewTitle(e.target.value)} />
                
                <button onClick={() => ConfirmEditTitle()} className='editTitleButton'><AiOutlineCheck /></button></>
                : <div className='element-title'>{title}</div>}
            
              <div className='element-controls'>

              {/* <IconContext.Provider value={{ size: "1.5rem", className: 'SVGwhite' }}> <GrLinkPrevious onClick={() => PreviousExercise()} /> </IconContext.Provider>
              <IconContext.Provider value={{ size: "1.5rem", className: 'SVGwhite' }}> <GrLinkNext onClick={() => NextExercise()}/> </IconContext.Provider>
               */}
              <Dropdown>
                <Dropdown.Toggle variant="ello">
                  <IconContext.Provider value={{ size: "1.75rem", className: 'SVGwhite' }}> <GrMenu /> </IconContext.Provider>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="exercise-hamburger">
                  <IconContext.Provider value={{ className: 'dropdownIcons' }}>
                    <Dropdown.Item onClick={() => dispatch(addLine())}><GrAdd />Add exercise</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(clearRoutine())}><GrTrash />Clear exercises</Dropdown.Item>
                    <Dropdown.Item onClick={() => setEditTitleToggle(true)}><GrBlockQuote />Rename routine</Dropdown.Item>
                    <Dropdown.Item ><GrDocumentDownload />Load routine</Dropdown.Item>
                    <Dropdown.Item ><FaRegSave />Save routine</Dropdown.Item>
                    <Dropdown.Item onClick={() => HandleFinishWorkout()}><AiOutlineCheck />Finish workout</Dropdown.Item>
                  </IconContext.Provider>
                </Dropdown.Menu>
              </Dropdown>
              </div>
              
              </div>
              
            <div className={`element-bottom`}>
          
                {exercises.map((item, iterator) =>
                
                <Summary
                       id={iterator}
                       key={iterator}
                       current={item}
                       activeExercise={activeExercise}
                       setActiveExercise={(id) => setActiveExercise(id)}
                />     
                
              )}

          </div>
          </motion.div>
        </motion.div>
          

     </AnimateSharedLayout>
        <Navbar />
        
      </>
    )
}

function ConfirmationWrapper(props) {

  const active = props.active
  const [DialogActive, setDialogActive] = useState(false);

  if (active) {
    return (
      <>
        <div className='confirmationButtons'>
        <div className='confirmationButton'>Confirm</div>
        <div className='confirmationButton'>Cancel</div>
          </div>
      </>
    );
  }
  
  return props.children
}

