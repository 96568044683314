import React from 'react'


export function ErrorScreen() {
    
  return (
      
    <React.Fragment>
         this page dosent exist ya nobhead
      </React.Fragment>
      
  )
}
