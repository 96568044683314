import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './redux/store'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react';

import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/style.css';
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store);


root.render(
<Provider store={store}>
<PersistGate persistor={persistor}>

       
      <App />
      
  </PersistGate>
</Provider>
);


