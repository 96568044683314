import React, { useState, useEffect } from 'react';

export function Stopwatch() {

    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const toggle = () => {setSeconds(0); setIsActive(!isActive)}
    
    const date = new Date(null);
    date.setSeconds(seconds)
    const formattedTime = date.toISOString().slice(11, 19);
    
  useEffect(() => {
    let interval = null;
      if (isActive) { interval = setInterval(() => {setSeconds(seconds => seconds + 1); }, 1000); }
      else if (!isActive && seconds !== 0) { clearInterval(interval); }
      return () => clearInterval(interval);
    }, [isActive, seconds]);

  return (

      <div className="timer">
      <div className={`timer-circle stopwatch ${isActive ? 'break' : ''}`}
        onClick={() => toggle()}> {isActive ? "Stop" : "Start"} </div>

      <div className='timer-maintext stopwatch'>
          <span>{formattedTime}</span>
      </div>   
      </div>

  );
};
