import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDGbb9kcCk9aftc4l8e5gtc19ixGOODMkg",
  authDomain: "mylifts-ca089.firebaseapp.com",
  databaseURL: "https://mylifts-ca089-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mylifts-ca089",
  storageBucket: "mylifts-ca089.appspot.com",
  messagingSenderId: "115008028847",
  appId: "1:115008028847:web:e0c43a2db7fe0dcc11545a",
  measurementId: "G-DKE6P0CR46"
};

export const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth(app)


