import React from 'react'
import {GiWeightLiftingUp} from "react-icons/gi"
import Dropdown from 'react-bootstrap/Dropdown';
import { IconContext } from "react-icons";
import { GoGear } from "react-icons/go";
import { Profile } from "../Profile"
import { Navbar } from "../ui/Navbar"
import { motion } from "framer-motion";

export function Home() {

  
  
  return (
      
    <React.Fragment>

          <motion.div layout className="element-container"
          initial={{ scale: 0.97 }}
          animate={{ scale: 1 }}
          exit={{ x: "100%", opacity: 0 }}
          transition={{ duration: 0.2 }}>

          <div className="element">
          <div className='element-top'>
          <div className='element-title'>SESSIONS</div>
            
          </div>
          
            
          <div className='element-bottom'>
          <Profile />
          </div>
         
          {/* <div className='element-grid-3col'>
            <div className='activities-stat'>Next in rotation:</div>
           
            <div className='activities-stat'>e</div>
          </div> */}
            
            
            
    
        
        
        </div> 
        
{/* <div className="element">
          <div className='element-top'>
          <div className='element-title'>Routines</div>
            <div className='element-controls'>
            <Dropdown>
                <Dropdown.Toggle variant="ello">
                  <IconContext.Provider value={{ size: "1.75rem", className: 'SVGwhite' }}> <GoGear /> </IconContext.Provider>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="exercise-hamburger">
                  <IconContext.Provider value={{ className: 'dropdownIcons',  size: "1.4rem", }}>
                    <Dropdown.Item >Stopwatch</Dropdown.Item>
                    <Dropdown.Item >Stopwatch</Dropdown.Item>
                  </IconContext.Provider>
                </Dropdown.Menu>
              </Dropdown>
          </div> 
          </div>
        <div className='element-grid'>
          <ExerciseItem />
          <ExerciseItem />
          <ExerciseItem />
          <ExerciseItem />
          <ExerciseItem />
          <ExerciseItem />
          </div>
        </div>  */}
      
        

      


        </motion.div> 

  

      <Navbar />
      
      </React.Fragment>
      
  )
}

function ExerciseItem() {
  
  return (
    <>
      <div className="exercise-item">
        
      Push HIIT
        
      
    </div>
    </>  
  )
}