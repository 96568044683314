import React, { useState, useEffect } from 'react';
import { AiOutlineHome, AiOutlineBarChart } from "react-icons/ai";
import { BiDumbbell } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import { IconContext } from "react-icons";
import { IoIosTimer, IoMdStopwatch } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";

export function Navbar() {

  return (

    <div className='navbar'>
    
    <NavLink to="/home" relative="path" className={({ isActive }) => isActive ? "navbar-item-active" : "navbar-item-inactive"}>
      <div className='navbar-item'>
        <IconContext.Provider value={{size: "1.5rem", }}>
          <AiOutlineHome />
        </IconContext.Provider>
          Home</div>
          </NavLink>
      
    <NavLink to="/overview" relative="path" className={({ isActive }) => isActive ? "navbar-item-active" : "navbar-item-inactive"}>
        <div className='navbar-item'>
        <IconContext.Provider value={{size: "1.5rem", }}>
          <BiDumbbell />
        </IconContext.Provider>
          Workout</div>
          </NavLink>
      
    <NavLink to="/timer" relative="path" className={({ isActive }) => isActive ? "navbar-item-active" : "navbar-item-inactive"}> 
        <div className='navbar-item'>
        <IconContext.Provider value={{size: "1.5rem", }}>
        <IoMdStopwatch />
        </IconContext.Provider>
        Timer</div>
      </NavLink>
      
      <NavLink to="/stats" relative="path" className={({ isActive }) => isActive ? "navbar-item-active" : "navbar-item-inactive"}> 
        <div className='navbar-item'>
        <IconContext.Provider value={{size: "1.5rem", }}>
        <AiOutlineBarChart />
        </IconContext.Provider>
          Stats</div>
          </NavLink>

        <div className='navbar-item'>
        <IconContext.Provider value={{size: "1.5rem", }}>
          <GoSettings />
        </IconContext.Provider>
        Settings</div>
      
        
      
    </div>

  )
};
